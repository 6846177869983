<template>
  <div class="">分支分支</div>
</template>

<script>
export default {
  name: "branch"
}
</script>

<style scoped>

</style>